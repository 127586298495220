/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertImageToPDF } from '../helpers/Helpers';
import privateAxios from '../utils/privateAxios';

//======================================== CONTEXTO ========================================//
const BecarioContext = createContext();
//======================================== CONTEXTO ========================================//
// Función para manejar errores de autenticación

//======================================== PROVIDER ========================================//
const BecarioProvider = ({ children }) => {
    const navigate = useNavigate();

    //======================================== FUNCIONES DE AYUDA ========================================//
    /**
     * Maneja errores de autenticación y otros errores de API.
     * @param {Object} error - Objeto de error de Axios.
     */
    const handleError = (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) 
            navigate('/login');
        return {succes: false, state: false}
    };

    /**
     * Obtiene los datos del becario, permitiendo especificar parámetros adicionales en la URL
     * y parámetros de consulta.
     * @param {Object} options - Opciones para configurar la solicitud.
     * @param {string} [options.urlPath=''] - Segmento adicional de URL para la solicitud.
     * @param {Object} [options.queryParams=null] - Parámetros de consulta para la solicitud.
     * @returns {Promise<Object>} Datos del becario.
     */
    const getBecarioData = async ({ urlPath = '', queryParams = null } = {}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.get(`/becarioUser${urlPath}`, config);
            return response.data.data ?? null;
        } catch (error) {
            handleError(error);
        }
    };

    /**
     * Actualiza los datos del becario, permitiendo especificar un segmento de URL, un cuerpo de solicitud,
     * y parámetros de consulta.
     * @param {Object} options - Opciones para configurar la solicitud.
     * @param {string} [options.urlPath=''] - Segmento adicional de URL para la solicitud.
     * @param {Object} [options.body={}] - Cuerpo de la solicitud para actualizar datos del becario.
     * @param {Object} [options.queryParams=null] - Parámetros de consulta para la solicitud.
     * @returns {Promise<Object|null>} Datos del becario actualizados o null si no hay datos.
     */
    const putBecarioData = async ({ urlPath = '', body = {}, queryParams = null } = {}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.put(`/becarioUser${urlPath}`, body, config);
            return response.data.data ?? null;
        } catch (error) {
            return handleError(error);
        }
    };

    /**
     * Actualiza los datos del becario, permitiendo especificar un segmento de URL, un cuerpo de solicitud,
     * y parámetros de consulta.
     * @param {Object} options - Opciones para configurar la solicitud.
     * @param {string} [options.urlPath=''] - Segmento adicional de URL para la solicitud.
     * @param {Object} [options.body={}] - Cuerpo de la solicitud para actualizar datos del becario.
     * @param {Object} [options.queryParams=null] - Parámetros de consulta para la solicitud.
     * @returns {Promise<Object|null>} Datos del becario actualizados o null si no hay datos.
     */
    const postBecarioData = async ({ urlPath = '', body = {}, queryParams = null } = {}) => {
        try {
            const config = queryParams ? { params: queryParams } : {};
            const response = await privateAxios.post(`/becarioUser${urlPath}`, body, config);
            return response.data.data ?? null;
        } catch (error) {
            return handleError(error);
        }
    };

    /**
     * Guarda un archivo PDF en el servidor.
     * @param {Object} body - Objeto con los datos del archivo (id, campo).
     * @param {File} file - El archivo PDF a subir.
     * @returns {Object} - Respuesta del servidor.
     */
    const uploadBecarioPDF = async (formData, url) => {
        try {
            // Extraer el archivo desde FormData
            const file = formData.get('file');
            
            if (!file) {
                throw new Error('No se proporcionó un archivo.');
            }
    
            const isImage = ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
            const processFile = isImage 
                ? await new Promise((resolve, reject) => {
                    convertImageToPDF(file, (error, pdfBlob) => {
                        if (error) {
                            reject(error);  // Rechazar el Promise si hay un error
                        } else {
                            resolve(pdfBlob);  // Resolver con el blob PDF
                        }
                    });
                }).catch(error => {
                    console.error('Error al convertir imagen a PDF:', error);
                    return null;
                }) 
                : file;
    
            if (!processFile) return null;
    
            // Reemplazar el archivo en FormData con el archivo procesado
            formData.set('file', processFile);
    
            // Hacer la solicitud POST con FormData
            const response = await privateAxios.post(`/becarioUser${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            
            return response.data.success;
        } catch (error) {
            handleError(error);
            return null;
        }
    };

    /**
     * Obtiene un archivo PDF del servidor.
     * @param {Object} params - Objeto con los parámetros del archivo (fileName, campo).
     * @returns {boolean} - Indica si la operación fue exitosa.
     */
    const getBecarioPDF = async (path, body) => {
        try {

            const response = await privateAxios.post(`/becarioUser/${path}`, body,  {
                responseType: 'blob',
            });

            const contentType = response.headers['content-type'];
            if (contentType === 'application/pdf') {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
                return true;
            } else {
                return false;
            }
        } catch (error) {
            handleError(error)
            return null;
        }
    };


     //======================================== FUNCIONES DEL CONTEXT ========================================//

    //_____________________fetch_____________________
    /**
     * Obtiene los datos del becario autenticado.
     * @returns {Object} Datos del becario.
     */
    const fetchBecarioBecario = async () => {
        return await getBecarioData({ urlPath: '' });
    };

    /**
     * Obtiene los registros académicos del becario autenticado.
     * @returns {Object} Registros académicos del becario.
     */
    const fetchBecarioRegistrosAcademicos = async () => {
        return await getBecarioData({ urlPath: '/registros-academicos' });
    };

    /**
     * Obtiene los beneficios económicos del becario autenticado.
     * @returns {Object} Beneficios económicos del becario.
     */
    const fetchBecarioBeneficiosEconomicos = async () => {
        return await getBecarioData({ urlPath: '/beneficios-economicos' });
    };

    /**
     * Obtiene los campos del formulario para el userBecario.
     * @returns {Object} Campos del formulario.
     */
    const fetchBecarioFields = async () => {
        return await getBecarioData({ urlPath: '/form' });
    };

    /**
     * Obtiene los reembolsos de un becario por su ID.
     * @param {number} id - ID del becario.
     * @returns {Object} Reembolsos del becario.
     */
    const fetchBecarioBenfReemById = async (id) => {
        return await getBecarioData({ urlPath: `/Reembolsos/${id}` });
    };

    /**
     * Envía la carta de compromiso a un becario especificado por su ID.
     * @returns {Object} - Respuesta del servidor.
     */
    const fetchCertificadoBecario = async () => {
        return await getBecarioData({ urlPath: '/cartaBecario' });
    };

    
    /**
     * Envía la carta de compromiso a un becario especificado por su ID.
     * @returns {Object} - Respuesta del servidor.
     */
    const fetchEncuesta = async ( numEncuesta ) => {
        return await getBecarioData({ urlPath: `/encuesta/${numEncuesta}` });
    };

    //_____________________put_____________________
    /**
     * Actualiza los datos del becario autenticado.
     * @param {Object} body - Datos para actualizar.
     * @returns {Object} Datos actualizados.
     */
    const putBecarioUpdate = async (body) => {
        return await putBecarioData({ urlPath: '', body });
    };

    //_____________________post_____________________
    /**
     * Sube los datos de la encuesta.
     * @param {Object} body - Datos para actualizar.
     * @returns {Object} Datos actualizados.
     */
    const postEncuesta = async (num, body) => {
        return await postBecarioData({ urlPath: `/encuesta/${num}`, body });
    };

    //_____________________pdf_____________________
     /**uploadBecarioPDF
     * Guarda un archivo PDF en el servidor.
     * @param {FormData} formData - Formulario de datos que incluye el archivo.
     * @returns {Object} - Respuesta del servidor.
     */
     const guardarBecarioArchivoPDF = async (formData) => {
        return await uploadBecarioPDF (formData, '/guardarArchivoPDF');
    };

    /**
     * Guarda un archivo PDF de factura en el servidor.
     * @param {number} beneficioEconomicoId - ID del beneficio económico.
     * @param {FormData} formData - Formulario de datos que incluye el archivo.
     * @returns {Object} - Respuesta del servidor.
     */
    const guardarBecarioFacturaPDF = async (beneficioEconomicoId, formData) => {     
        return await uploadBecarioPDF(formData, `/guardarFacturaPDF/${beneficioEconomicoId}`);
    };

    /**
     * Obtiene un archivo PDF del servidor.
     * @param {Object} body - Objeto con los parámetros del archivo (fileName, campo).
     * @returns {boolean} Indica si la operación fue exitosa.
     */
    const obtenerBecarioArchivoPDF = async (campo) => {
        return await getBecarioPDF(`obtenerArchivoPDF`, { campo });
    };

    /**
     * Visualiza una factura archivo PDF.
     * @param {string} fileName - Nombre del archivo de factura.
     * @returns {boolean} Indica si la operación fue exitosa.
     */
     const fetchBecariofetchViewFacturaPDF = async (fileName) => {       
        return await getBecarioPDF(`factura`,  { fileName } );
    };

    // Exponer las funciones a través del contexto
    const value = {
        fetchBecarioBecario,
        fetchBecarioRegistrosAcademicos,
        fetchBecarioBeneficiosEconomicos,
        fetchBecarioFields,
        fetchEncuesta,
        putBecarioUpdate,
        postEncuesta,
        guardarBecarioArchivoPDF,
        guardarBecarioFacturaPDF,
        obtenerBecarioArchivoPDF,
        fetchBecarioBenfReemById,
        fetchBecariofetchViewFacturaPDF,
        fetchCertificadoBecario,
    };

    return (
        <BecarioContext.Provider value={value}>
            {children}
        </BecarioContext.Provider>
    );
};

export { BecarioProvider, BecarioContext };