import { notification } from 'antd';
import { deleteName, deleteRefreshToken, deleteRole, deleteToken, deletePermisos, getPermisos, deleteSurveyNumber } from '../utils/indexedDB';
import Compressor from 'compressorjs';
import { jsPDF } from 'jspdf';
import { disconnectSocket } from './socket';

/**
 * Muestra una notificación en pantalla.
 * @param {string} type - El tipo de notificación ('success', 'error', 'info', 'warning').
 * @param {string} message - El título de la notificación.
 * @param {string} description - La descripción de la notificación.
 * @param {number} duration - La duración de la notificación en segundos (por defecto 10).
 */
const showNotification = (type, message, description, duration = 10) => {
    notification[type]({
        message: message,
        description: description,
        duration: duration,
    });
};

// Function to handle session cleanup
const handleSessionCleanup = async () => {
    disconnectSocket();
    await deleteToken();
    await deleteRole();
    await deleteRefreshToken();
    await deleteName();
    await deletePermisos();
    await deleteSurveyNumber();
};

const convertImageToPDF = (imageFile, callback) => {
    const isImage = ['image/jpeg', 'image/png', 'image/jpg'].includes(imageFile.type);

    if (!isImage) 
        return callback(new Error('Formato de imagen no compatible. Solo se aceptan JPEG, JPG y PNG.'), null);

    const imageFormat = imageFile.type === 'image/png' ? 'PNG' : imageFile.type === 'image/jpeg' ? 'JPEG' : 'JPG';

    new Compressor(imageFile, {
        quality: 0.8, // Compresión para reducir el tamaño del archivo
        maxWidth: 1920,
        maxHeight: 1080,
        success(compressedImage) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imgData = e.target.result;
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'px',
                    format: 'a4'
                });

                const img = new Image();
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    const width = pdf.internal.pageSize.getWidth();
                    const height = imgHeight * width / imgWidth;

                    pdf.addImage(imgData, imageFormat, 0, 0, width, height);

                    // Utilizar 'output' para obtener el contenido del PDF como ArrayBuffer
                    const pdfArrayBuffer = pdf.output('arraybuffer');
                    const blob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
                    callback(null, blob); // Devolver el blob directamente
                };
                img.src = imgData;
            };
            reader.onerror = (error) => {
                callback(error, null);
            };
            reader.readAsDataURL(compressedImage);
        },
        error(err) {
            console.error('Error during image compression:', err);
            callback(err, null);
        }
    });
};

const cargarPermisos = async (selectedParentKey, setPermisoEdit, setPermisoPost) =>{
    const permisos = await getPermisos()
    
    if(permisos[selectedParentKey]['edit']) setPermisoEdit(true) 
    if(permisos[selectedParentKey]['post']) setPermisoPost(true) 
}

export { showNotification, handleSessionCleanup, convertImageToPDF, cargarPermisos };